const autoNgTemplateLoaderTemplate1 = require('./license-item.html');

import controller from './license-item.controller';

export const licenseFormItem = {
  bindings: {
    keyValidation: '<',
    value: '<',
    index: '<',

    onChange: '&',
    onRemoveClick: '&',
  },
  controller,
  templateUrl: autoNgTemplateLoaderTemplate1,
};
