export type EdgeStack = {
  Id: number;
  Name: string;
};

export enum EditorType {
  Compose,
  Kubernetes,
  Nomad,
}
