const autoNgTemplateLoaderTemplate1 = require('./licenses-form.html');

import controller from './licenses-form.controller';
import './licenses-form.css';

export const licensesForm = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    licenses: '<',
    onSubmitSuccess: '<',
  },
};
