const autoNgTemplateLoaderTemplate1 = require('./nodesDatatable.html');

angular.module('portainer.kubernetes').component('kubernetesNodesDatatable', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'GenericDatatableController',
  bindings: {
    titleText: '@',
    titleIcon: '@',
    dataset: '<',
    tableKey: '@',
    orderBy: '@',
    refreshCallback: '<',
    hasK8sClusterNodeR: '<',
    useServerMetrics: '<',
  },
});
